import React, { useRef } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import {
  Text,
  TextTypography as TYPOGRAPHY,
  HeroImage,
  ImageFocalPointPresets,
  ImageLoadingBehaviorOptions,
  ImageResizeOptions,
} from 'wix-ui-tpa/cssVars';

import { HeaderViewModel } from '../../../../service-page-view-model/header-view-model/headerViewModel';
import { ImagePositionOptions, MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { useSizeListener } from '../useSizeListener';
import { useDimensions } from '../useDimensions';
import { st, classes } from './Header.st.css';

import settingsParams from '../../settingsParams';
import BookButton from '../BookButton/BookButton';
import { useAddVisibilityClass } from '../../hooks/useAddVisibilityClass';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';

type HeaderProps = {
  viewModel: HeaderViewModel;
  className?: string;
};

const imagePositionToFocalPoint = {
  [ImagePositionOptions.BOTTOM]: ImageFocalPointPresets.bottomCenter,
  [ImagePositionOptions.BOTTOM_LEFT]: ImageFocalPointPresets.bottomLeft,
  [ImagePositionOptions.BOTTOM_RIGHT]: ImageFocalPointPresets.bottomRight,
  [ImagePositionOptions.LEFT]: ImageFocalPointPresets.centerLeft,
  [ImagePositionOptions.RIGHT]: ImageFocalPointPresets.centerRight,
  [ImagePositionOptions.TOP]: ImageFocalPointPresets.centerTop,
  [ImagePositionOptions.TOP_LEFT]: ImageFocalPointPresets.topLeft,
  [ImagePositionOptions.TOP_RIGHT]: ImageFocalPointPresets.topRight,
  [ImagePositionOptions.MIDDLE]: ImageFocalPointPresets.center,
};

const Header: React.FC<HeaderProps> = ({ viewModel, className }) => {
  const settings = useSettings();
  const headerRef = useRef<HTMLDivElement>(null);
  const { isMobile, isRTL } = useEnvironment();
  const headerDimensions = useSizeListener(headerRef);
  const widgetDimensions = useDimensions();
  const { addVisibilityClass } = useAddVisibilityClass();
  const { shouldBeVisible } = useVisibilityCheck();

  const serviceImage = () => {
    return shouldBeVisible('headerImageVisibility') && viewModel?.image ? (
      <div
        className={addVisibilityClass(
          classes.media,
          classes.mediaVisibility,
          'headerImageVisibility',
        )}
      >
        {headerDimensions.width && headerDimensions.height && (
          <HeroImage
            key={`${JSON.stringify(
              settings.get(settingsParams.headerImagePosition),
            )} ${viewModel?.image?.relativeUri} ${headerDimensions?.height} ${
              widgetDimensions.width
            }`}
            sourceHeight={viewModel.image.height}
            sourceWidth={viewModel.image.width}
            isInFirstFold={widgetDimensions.height > headerDimensions.height}
            resize={ImageResizeOptions.cover}
            src={viewModel?.image?.relativeUri || ''}
            data-hook="header-img"
            width={widgetDimensions.width}
            height={headerDimensions.height}
            alt={viewModel?.image?.altText}
            loadingBehavior={ImageLoadingBehaviorOptions.blur}
            focalPoint={
              imagePositionToFocalPoint[
                settings.get(settingsParams.headerImagePosition)
              ]
            }
            fluid
            className={classes.image}
            isSEOBot={viewModel?.isSEO}
          />
        )}
      </div>
    ) : null;
  };

  const serviceTitle = () =>
    shouldBeVisible('headerTitleVisibility') ? (
      <Text
        data-hook="header-title"
        className={addVisibilityClass(
          st(classes.title, { isRTL }, className),
          classes.titleVisibility,
          'headerTitleVisibility',
        )}
        typography={TYPOGRAPHY.largeTitle}
        tagName={settings.get(settingsParams.headerTitleHtmlTag)}
      >
        {viewModel?.title}
      </Text>
    ) : null;

  const bookButton = () =>
    shouldBeVisible('headerBookButtonVisibility') && viewModel.isBookable ? (
      <BookButton
        dataHook="header-book-button"
        renderedAt="HEADER"
        className={addVisibilityClass(
          '',
          classes.headerBookButtonVisibility,
          'headerBookButtonVisibility',
        )}
      />
    ) : null;

  return (
    <RenderLocationProvider value={MainComponents.HEADER}>
      <header
        className={st(
          classes.root,
          {
            alignment: settings.get(settingsParams.headerTitleAlignment),
            isMobile,
            isRTL,
          },
          className,
        )}
        aria-label={viewModel?.title}
        data-hook="header-wrapper"
        ref={headerRef}
      >
        {serviceImage()}
        {serviceTitle()}
        {bookButton()}
      </header>
    </RenderLocationProvider>
  );
};

export default Header;
